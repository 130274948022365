import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import axios from 'axios';
import useWithdrawalContext from '../../../hooks/useWithdrawalContext';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';

// components
import StepsContainer from '../../deposit/steps/StepsContainer';
import CurrencyDropdown from '../../components/CurrencyDropdown';
import Input from '../../../components/Input';
import Button from '../../../components/CustomButton';

const FirstStep = () => {
  const { address } = useAccount(); // Получаем адрес пользователя
  const [availableToWithdrawAPI, setAvailableToWithdrawAPI] = useState<string>('0.00');
  const [dsfLpBalance, setDsfLpBalance] = useState<string>('0.000000000000000000');
  const [loading, setLoading] = useState<boolean>(true); // Флаг загрузки

  const {
    selectedCurrency,
    changeSelectedCurrency,
    amountToWithdraw,
    changeAmountToWithdraw,
    changeCurrentStep,
    changeAmountToWithdrawInPercentage,
  } = useWithdrawalContext();
  // const {
  //   userDeposits,
  //   withdrawableIncome,
  // } = useGlobalContext();
  const { t } = useTranslation('withdraw');

  // Вызов API для получения данных
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!address) return; // Если адреса нет, не вызываем API

        const response = await axios.get(`https://api2.dsf.finance/request/walletinfo?wallet=${address}&apikey=a5d8b4f2-6e3a-49b1-8c5e-3d7b2a4c6f1e`);

        if (response.status === 200) {
          const data = response.data;
          setAvailableToWithdrawAPI(data.available_to_withdraw);
          setDsfLpBalance(data.dsf_lp_balance);
        } else {
          console.error("Ошибка API: статус не 200");
        }
      } catch (error) {
        console.error("Ошибка при получении данных из API:", error);
      } finally {
        setLoading(false); // Выключаем индикатор загрузки после завершения запроса
      }
    };

    fetchData();
  }, [address]);
  
  const availableToWithdraw = useMemo(() => {
    return parseFloat(availableToWithdrawAPI) || 0;
  }, [availableToWithdrawAPI]);

  const isWithdrawAmountValid = useMemo(() => {
    if (!amountToWithdraw || !selectedCurrency) return false;
    if (Number(amountToWithdraw) <= 0) return false;

    return Number(amountToWithdraw) <= availableToWithdraw;
  },[amountToWithdraw, availableToWithdraw, selectedCurrency]);

  useEffect(() => {
    changeAmountToWithdrawInPercentage(+amountToWithdraw / availableToWithdraw);
  }, [amountToWithdraw, availableToWithdraw, changeAmountToWithdrawInPercentage]);

  return (
    <StepsContainer title={t('deal')}>
      <div className="mt-6 text-[14px]">
        {t('balance_includes')}
      </div>
      <div className="tablet:flex gap-5 items-end">
        <div className="mt-2 tablet:w-1/2">
          <label className="font-medium text-[0.75rem]">{t('asset')}</label>
          <CurrencyDropdown selectedCurrency={selectedCurrency} changeSelectedCurrency={changeSelectedCurrency} />
        </div>
        <div className="mt-6 tablet:mt-2 tablet:w-1/2">
          <Input
            withRange
            value={amountToWithdraw}
            onChange={changeAmountToWithdraw}
            maxValue={availableToWithdraw}
            label={
              <div className="flex items-center justify-between">
                <span>{t('amount_usd')}</span>
                <div className="flex items-center text-sm">
                  {t('manged_in_DSF')}:
                  <span className="ml-1 text-gray-900">
                      $ {parseFloat(availableToWithdraw.toFixed(2)).toLocaleString('en-US')}
                  </span>
                </div>
              </div>
            }
          />
        </div>
      </div>
      <div className="mt-6">
        <Button
          variant="filled"
          onClick={() => changeCurrentStep(1)}
          disabled={!isWithdrawAmountValid}
        >
          {t('next', { ns: 'common' })}
        </Button>
      </div>
    </StepsContainer>
  )
};

export default FirstStep;
